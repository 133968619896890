import {Component, Input} from '@angular/core';
import {ProgressIndicatorLocation} from 'ngx-guided-tour';

@Component({
  selector: 'rea-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @Input() public position = ProgressIndicatorLocation.TopOfTourBlock;

}
