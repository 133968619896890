import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'rea-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Basic chip wrapping having Angular Material stroked button as default
 */
export class ChipComponent {

  /**
   * Adjust color scheme to the material palette.
   * Possible values: 'primary', 'accent', 'warn' and undefined
   */
  @Input() color: ThemePalette = null;
  /**
   * Whether the user can interact with the chip or not.
   */
  @Input() disabled: boolean = null;

  @Output('clickHandler') click = new EventEmitter();

  /**
   * Handler for the click event(handles click only from the inner component and does not get fired on the wrapper)
   * @param event
   */
  clickHandlerMethod(event: Event): void {
    this.click.emit(event);
  }

}
