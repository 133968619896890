import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantService } from '@core/store/tenant.service';
import { map } from 'rxjs/operators';
import { countries } from '@core/helpers/countries';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  private readonly selectedLanguageKey = 'rea-language';
  private language$ = new BehaviorSubject<string>(null);
  private languageDisabled: boolean;
  private countries = countries;

  /**
   * Returns whether language selector is disabled or not
   * returns true/ false
   */
  get isLanguageDisabled(): boolean {
    return this.languageDisabled;
  }

  /**
   * sets the language selector with the incoming true/false value
   * @param value: boolean
   */
  set setLanguageDisabled(value: boolean) {
    this.languageDisabled = value;
  }


  constructor(
    private tenantService: TenantService
  ) {
    const language = this.readLanguageFromStorage();
    this.language$.next(language);
  }

  /**
   * Returns an observable that emits the latest selection of language
   * @returns Stream of selected language
   */
  get(): Observable<string> {
    return this.language$.asObservable();
  }

  /**
   * Saves the selected tenant in localstorage, and updates behaviorsubject
   * @param language Language to be used
   */
  set(language): void {
    localStorage.setItem(this.selectedLanguageKey, JSON.stringify(language));
    this.language$.next(language);
  }

  /**
   * Returns a list of all languages specific to a tenant having a country
   * @returns A list of associated languages
   */
  getCountryLanguage(): Observable<LanguageInfo[]> {
    return this.tenantService.get().pipe(map(tenant => {
      let result = [];
      this.countries.some((country) => {
        if (country.tenants.map(t => t.code).includes(tenant)) {
          result = country.languages;
        }
      });
      return result.map(i => { i.displayedName = `${i.name} : ${i.isoCode}`; return i; });
    }));
  }

  // Sanitize and handle null values
  private readLanguageFromStorage(): string {
    let lang = '';
    try {
      lang = JSON.parse(localStorage.getItem(this.selectedLanguageKey)) as string;
    }
    catch { }
    return lang;
  }
}

export interface LanguageInfo {
  name: string;
  isoCode: string;
  displayedName?: string;
}
