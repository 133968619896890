<mat-form-field appearance="outline" class="facade" floatLabel="always">
    <mat-label>
        <ng-content></ng-content>
    </mat-label>

    <ng-container *ngIf="matAutocomplete; else simple">
        <input matInput [type]="type" [placeholder]="placeholder" [formControl]="control" [readonly]="readonly"
            [matAutocomplete]="matAutocomplete">
    </ng-container>
    <ng-template #simple>
        <input matInput [type]="type" [placeholder]="placeholder" [formControl]="control" [readonly]="readonly">
    </ng-template>

    <span matPrefix class="outline-fix">
        <ng-content select="[prefix]"></ng-content>
    </span>
    <span matSuffix class="outline-fix">
        <ng-content select="[suffix]"></ng-content>
    </span>
    <mat-hint>
        <ng-content select="[hint]"></ng-content>
    </mat-hint>
    <mat-error>
        <ng-content select="[error]"></ng-content>
    </mat-error>
</mat-form-field>