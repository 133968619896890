import { Injectable } from '@angular/core';
import { UserModel } from '@core/auth/models/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly currentUserStroageKey = 'rea-user';
  private user$ = new BehaviorSubject<UserModel>(null);

  constructor() {
    const user = JSON.parse(localStorage.getItem(this.currentUserStroageKey) || 'null') as UserModel;
    this.user$.next(user);
  }

  /**
   * Returns an observable that emits the logged in user
   * @returns Stream of current user info
   */
  get(): Observable<UserModel> {
    return this.user$.asObservable();
  }

  /**
   * Saves the selected user in localstorage, and updates behaviorsubject
   * @param user Loggedin user
   */
  set(user: UserModel): void {
    localStorage.setItem(this.currentUserStroageKey, JSON.stringify(user));
    this.user$.next(user);
  }
}
