import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ResourceCenterService} from '@core/onboarding/resource-center.service';
import {Article} from '@core/onboarding/models/resource-center';

@Component({
  selector: 'rea-resource-center-article',
  templateUrl: './resource-center-article.component.html',
  styleUrls: ['./resource-center-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceCenterArticleComponent implements OnInit{

  @Input() article: Article;
  @Output() showOverview = new EventEmitter();
  articleContent: string;

  constructor(
    private resourceCenterService: ResourceCenterService
  ) { }

  ngOnInit(){
    this.articleContent = this.resourceCenterService.getArticleContent();
  }

  onScroll(el): void {
    let element;
    if (el.currentTarget.scrollTop > 10) {
      element = document.getElementById('dynamic-header');
      element.classList.add('sticky', 'flex-row');
    } else {
      element = document.getElementById('dynamic-header');
      element.classList.remove('sticky', 'flex-row');
    }
  }

  backToOverview(): void {
    this.showOverview.emit(true);
  }

}
