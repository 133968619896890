<mat-expansion-panel  class="always-fit-content" #panel [expanded]="panelOpenState!=null ? panelOpenState : expandedByDefault">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ng-content select="[title]"></ng-content>
        </mat-panel-title>

        <mat-panel-description>
            <ng-content select="[description]"></ng-content>
        </mat-panel-description>
    </mat-expansion-panel-header>

   <ng-template matExpansionPanelContent>
     <ng-content></ng-content>
   </ng-template>

</mat-expansion-panel>
