import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MODAL_DATA, MODAL_REF, ModalRef} from '@shared/modal/modal.component';

@Component({
  selector: 'rea-html-preview-modal',
  templateUrl: './html-preview-modal.component.html',
  styleUrls: ['./html-preview-modal.component.scss']
})
export class HtmlPreviewModalComponent implements OnInit {

  content: any;

  constructor(
    @Inject(MODAL_REF) private modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.data.data);
  }

  close(): void {
    this.modalRef.close();
  }
}


