import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '@core/store/user.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoggedIn(state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoggedIn(state);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.isLoggedIn(null);
  }

  /**
   * Checks if the user is logged in, and tries to log in if not.
   * @returns True if user should be allowed on the route; False if route should not be navigated to
   */
  private isLoggedIn(state): Observable<boolean> {

    // check if there's a user
    return this.userService.get().pipe(
      take(1),
      map((user) => {
        const exists = !!user;
        if (!exists) {

          // search URL is saved in local storage to be accessed after a successful login
          const searchState = { url : '/search', queryParams: state.root.queryParams};
          if (state.url.includes('/search?')) {
            localStorage.setItem('ngStorage-url', JSON.stringify(searchState));
          }

          this.router.navigate(['/login']);
        }
        return exists;
      })
    );
  }
}
