export const countries = [{
  name: 'Austria',
  tenants: [
    {
      code: 'sterling-mmat',
      name: 'MM Austria'
    },
    {
      code: 'sterling-seat',
      name: 'Saturn Austria'
    },
    {
      code: 'webshop-mmat',
      name: 'MM Austria'
    },
    {
      code: 'webshop-seat',
      name: 'Saturn Austria'
    }
  ],
  languages: [{
    name: 'Deutsch',
    isoCode: 'de_AT',
  }],
  flag: './assets/flags/AT.svg'
}, {
  name: 'Switzerland',
  tenants: [
    {
      code: 'sterling-mmch',
      name: 'MM Switzerland'
    },
    {
      code: 'webshop-mmch',
      name: 'MM Switzerland'
    }
  ],
  languages: [{
    name: 'Deutsch',
    isoCode: 'de_CH',
  }, {
    name: 'Italiano',
    isoCode: 'it_CH',
  }, {
    name: 'Français',
    isoCode: 'fr_CH'
  }],
  flag: './assets/flags/CH.svg'
}, {
  name: 'Spain',
  tenants: [
    {
      code: 'sterling-mmes',
      name: 'MM Spain'
    },
    {
      code: 'webshop-mmes',
      name: 'MM Spain'
    }
  ],
  languages: [{
    name: 'Español',
    isoCode: 'es_ES',
  }],
  flag: './assets/flags/ES.svg'

}, {
  name: 'Belgium',
  tenants: [
    {
      code: 'sterling-mmbe',
      name: 'MM Belgium'
    },
    {
      code: 'webshop-mmbe',
      name: 'MM Belgium'
    }
  ],
  languages: [{
    name: 'Vlaams',
    isoCode: 'nl_BE',
  }, {
    name: 'Français',
    isoCode: 'fr_BE',
  }],
  flag: './assets/flags/BE.svg'
}, {
  name: 'Default',
  tenants: [
    {
      code: 'sterling-default',
      name: 'MM I Default'
    },
    {
      code: 'webshop-default',
      name: 'MM Default'
    }
  ],
  languages: [{
    name: 'English',
    isoCode: 'en_US',
  }],
  flag: './assets/flags/world.svg'
}, {
  name: 'Testing',
  tenants: [
    {
      code: 'testing',
      name: 'Testing'
    }
  ],
  languages: [{
    name: 'English',
    isoCode: 'en_CA',
  }, {
    name: 'Français',
    isoCode: 'fr_CA',
  }],
  flag: './assets/flags/TEST.svg'
}, {
  name: 'Turkey',
  tenants: [
    {
      code: 'sterling-mmtr',
      name: 'MM Turkey'
    },
    {
      code: 'webshop-mmtr',
      name: 'MM Turkey'
    }
  ],
  languages: [{
    name: 'Türkçe',
    isoCode: 'tr_TR',
  }],
  flag: './assets/flags/TR.svg'
}, {
  name: 'Hungary',
  tenants: [
    {
      code: 'sterling-mmhu',
      name: 'MM Hungary'
    },
    {
      code: 'webshop-mmhu',
      name: 'MM Hungary'
    }
  ],
  languages: [{
    name: 'Magyar',
    isoCode: 'hu_HU',
  }],
  flag: './assets/flags/HU.svg'
}, {
  name: 'Germany',
  tenants: [
    {
      code: 'sterling-mmde',
      name: 'MM Germany'
    },
    {
      code: 'webshop-mmde',
      name: 'MM Germany'
    },
    {
      code: 'sterling-sede',
      name: 'Saturn Germany'
    },
    {
      code: 'webshop-sede',
      name: 'Saturn Germany'
    }
  ],
  languages: [{
    name: 'Deutsch',
    isoCode: 'de_DE',
  }],
  flag: './assets/flags/DE.svg'
}, {
  name: 'Sweden',
  tenants: [
    {
      code: 'sterling-mmse',
      name: 'MM Sweden'
    },
    {
      code: 'webshop-mmse',
      name: 'MM Sweden'
    }
  ],
  languages: [{
    name: 'Svenska',
    isoCode: 'sv_SE',
  }],
  flag: './assets/flags/SE.svg'
}, {
  name: 'Italy',
  tenants: [
    {
      code: 'sterling-mmit',
      name: 'MM Italy'
    },
    {
      code: 'webshop-mmit',
      name: 'MM Italy'
    }
  ],
  languages: [{
    name: 'Italiano',
    isoCode: 'it_IT',
  }],
  flag: './assets/flags/IT.svg'
},
{
  name: 'Netherlands',
  tenants: [
    {
      code: 'sterling-mmnl',
      name: 'MM Netherlands'
    },
    {
      code: 'webshop-mmnl',
      name: 'MM Netherlands'
    }
  ],
  languages: [{
    name: 'Nederlands',
    isoCode: 'nl_NL',
  }],
  flag: './assets/flags/NL.svg'
},
{
  name: 'Poland',
  tenants: [
    {
      code: 'sterling-mmpl',
      name: 'MM Poland'
    },
    {
      code: 'webshop-mmpl',
      name: 'MM Poland'
    }
  ],
  languages: [{
    name: 'Polski',
    isoCode: 'pl_PL',
  }],
  flag: './assets/flags/PL.svg'
}];
