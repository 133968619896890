import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalComponent, ModalConfiguration } from './modal.component';

@Injectable({
  providedIn: 'root'
})
/**
 * Hides the material modal factory implementation.
 */
export class ModalService {

  constructor(private dialog: MatDialog) { }

  /**
   * Opens a material modal instance and renders inside it the provided component.
   * If the component request data to be created, it can be provided through the optional parameter.
   * @param component Component to be rendered inside the modal.
   * @param data Data to be injected in the rendered component.
   * @returns Observable returning the submitted value when the modal is closed.
   */
  open(component: any, data?: any): Observable<any> {
    return this.dialog.open(ModalComponent, {
      data: {
        component,
        modalData: data
      } as ModalConfiguration
    }).afterClosed();
  }
}
