<div class="search-wrapper">

  <div class="progress-spinner" *ngIf="loadingSpinner">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <div *ngIf="!loadingSpinner">
    <h1>Search results for <b>{{order}}</b></h1>
    <div *ngIf="!emailList?.length && !archivedDocuments?.length && !migratedDocuments?.length">No results found for
      this
      order
    </div>

    <div class="search-results">
      <rea-search-emails *ngIf="emailList?.length"
                         [data]="emailList"
                         [tenant]="tenant"
                         [environment]="environment"
                         [audience]="audience"
                         (resendHistoryEmitter)="resendHistoryNotification($event)"
                         (requestEmitter)="handleDownloadRequest($event)">
      </rea-search-emails>

      <rea-search-documents *ngIf="archivedDocuments?.length"
                            [data]="archivedDocuments"
                            [tenant]="tenant"
                            [environment]="environment"
                            (requestEmitter)="handleDownloadRequest($event)">
      </rea-search-documents>

      <rea-search-migrated *ngIf="migratedDocuments?.length"
                           [data]="migratedDocuments"
                           (resendEmitter)="resendMigrationNotification($event)">
      </rea-search-migrated>
    </div>
  </div>
</div>
