import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';
import { UiComponentsModule } from '@shared/ui-components.module';
import { DashboardComponent } from '@main/dashboard/dashboard.component';
import { HomeComponent } from './main/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@env';
import { ForbiddenComponent } from './main/forbidden/forbidden.component';
import { UnauthorizedComponent } from './main/unauthorized/unauthorized.component';
import { ErrorInterceptor } from '@core/http/error.interceptor';
import { AuthorizationModule } from '@shared/authorization/authorization.module';
import { LoginComponent } from './main/login/login.component';
import { MatIconRegistry } from '@angular/material/icon';
import { CallCenterModule } from './call-center/call-center.module';
const envSpecificProviders = environment.envSpecificProviders || [];
import { NgxTextDiffModule } from 'ngx-text-diff';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { ResourceCenterComponent } from './shared/resource-center/resource-center/resource-center.component';
import { ResourceCenterArticleComponent } from './shared/resource-center/resource-center-article/resource-center-article/resource-center-article.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    LoginComponent,
    ResourceCenterComponent,
    ResourceCenterArticleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UiComponentsModule,
    HttpClientModule,
    AuthorizationModule,
    CallCenterModule,
    MatTooltipModule,
    NgxTextDiffModule,
    GuidedTourModule
  ],
  providers: [
    ...envSpecificProviders,
    GuidedTourService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, ds: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      ds.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
