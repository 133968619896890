<div class="modal-wrapper">
  <h2>Resend E-Mail</h2>

  <hr>
  <div class="modal-content">
    <div class="description-modal">
      <p><b>Would you like to resend this notification?</b></p>
    </div>
  </div>
  <div>
    <rea-button color="primary" (clickHandler)="confirm()">Resend</rea-button>
    <rea-button class="cancel-button" (clickHandler)="close()">Cancel</rea-button>
  </div>

</div>
