import {Component, Input, OnChanges, OnDestroy, OnInit, Optional, Self, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ControlValueAccessorBaseComponent} from '@utils/control-value-accessor-base.component';

@Component({
  selector: 'rea-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/**
 * Basic dropdown wrapping the select component from Angular Material.
 * Extends ControlValueAccess so it can bind it's value to properties either with [(ngModel)] or reactive forms.
 * Behavior changing properties (like disabled) are inherited implicitly by the ControlValueAccessor.
 * Content information is passed through @Inputs.
 */
export class DropdownComponent extends ControlValueAccessorBaseComponent<any> implements OnInit, OnDestroy, OnChanges {
  /**
   * Whether to allow selection of multiple values or not.
   * When true, the value of the control returns an array of the selected items.
   */
  @Input() multiple = false;
  /**
   * The dataset from which the options are generated.
   */
  @Input() options: any[] = [];
  /**
   * Whether the given dropdown has an icon prefix
   */
  @Input() hasIcon: false;
  /**
   * When dealing with objects, it represents the name of the property
   * that needs to be set as the control value.
   */
  @Input() key: string = null;
  /**
   * When dealing with objects, it represents the name of the property
   * that needs to be displayed for the user.
   */
  @Input() label: string = null;

  @Input() uppercase: string;

  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When initialized
    if (this.control) {
      // If the key selector changes, the value needs to be updated manually
      if (changes && changes.key) {
        this.control.setValue(undefined);
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
