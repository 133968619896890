<div class="side" [@openDrawer]="showInfoPanel ? 'open' : 'closed'">

  <!--  commented this section until Guidance feature implemented-->

  <!--  <div class="side-btn-container">-->
<!--    <rea-button class="resource-center-button" [ngClass]="{'opened-drawer': !showInfoPanel}"-->
<!--                (click)="resourceCenterState()">-->
<!--      <mat-icon>expand_more</mat-icon>-->
<!--      Need help?-->
<!--    </rea-button>-->
<!--  </div>-->
  <div class="info-panel" [ngClass]="{'opened-drawer': !showInfoPanel}">
    <ng-container *ngIf="infoPanelOverview">
      <div class="info-panel-header">
        <mat-icon class="material-icons-outlined library-icon">
          auto_stories
        </mat-icon>
        <h2 class="info-panel-title">Welcome to REA Library</h2>
        <p class="info-panel-description">REA Library is the place you can find plenty
          of useful information on how to best make use of the
          application</p>
      </div>
      <div class="info-panel-body">
        <div class="search-articles">
          <rea-input class="full-width-input" placeholder="Search through REA library" [(ngModel)]="searchValue"
                     (ngModelChange)="filterArticles()">
        <span prefix>
          <mat-icon class="search-icon">search</mat-icon>
        </span>
          </rea-input>
        </div>
        <div class="overflow-auto" *ngIf="filteredArticlesByValue?.length > 0 && searchValue.length">
          <div class="article-summary" *ngFor="let article of filteredArticlesByValue"
               (click)="infoPanelOverview = false; selectedArticle = article">
            <span>{{article?.title  | stringLength:55}}</span>
            <button type="button" mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="filteredArticlesByType.length > 0 && !searchValue.length">
          <h4 class="info-panel-subtitle">SUGGESTIONS FOR <b>{{infoSectionType | uppercase}}</b> SECTION</h4>
          <div class="article-summary" *ngFor="let article of filteredArticlesByType"
               (click)="infoPanelOverview = false; selectedArticle = article">
            <span>{{article?.title  | stringLength:55 }}</span>
            <button type="button" mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!infoPanelOverview">
      <rea-resource-center-article [article]="selectedArticle"
                                   (showOverview)="switchToOverview($event)"
      ></rea-resource-center-article>
    </ng-container>
  </div>
</div>
