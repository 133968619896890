import {Injectable, Input} from '@angular/core';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from './user.service';
import { ApplicationService } from './application.service';
import { countries } from '@core/helpers/countries';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private readonly selectedTenantKey = 'rea-tenant';
  private tenant$ = new BehaviorSubject<string>(null);
  private tenantDisabled: boolean;
  private countries = countries;
  /**
   * Returns whether tenant selector is disabled or not
   * returns true/ false
   */

  get isTenantDisabled(): boolean {
    return this.tenantDisabled;
  }

  /**
   * sets the tenant selector with the incoming true/false value
   * @param value: boolean
   */
  set setTenantDisabled(value: boolean) {
    this.tenantDisabled = value;
  }

  constructor(
    private userService: UserService,
    private appService: ApplicationService
  ) {
    const tenant = this.readTenantFromStorage();
    this.tenant$.next(tenant);
  }

  /**
   * Returns an observable that emits the latest selection of the desired tenant
   * @returns Stream of selected tenants
   */
  get(): Observable<string> {
    return this.tenant$.asObservable();
  }

  /**
   * Saves the selected tenant in localstorage, and updates behaviorsubject
   * @param tenant Tenant to be used
   */
  set(tenant: string): void {
    localStorage.setItem(this.selectedTenantKey, JSON.stringify(tenant));
    this.tenant$.next(tenant);
  }

  /**
   * Returns the list of available tenants the user may choose from
   * @returns List of available tenants
   */
  getTenantList(): Observable<string[]> {
    const tenants = [];

    return forkJoin([
      this.userService.get().pipe(take(1)),
      this.appService.get().pipe(take(1))
    ]).pipe(map(([user, app]) => {
      if (!user?.tenants?.length || !app) { return []; }

      this.countries.some((country) => {
        const countryTenants = country.tenants;
        countryTenants.filter(tenant => {
          if (user.tenants.includes(tenant.code)){
            tenants.push({code: tenant.code, name: tenant.name, flag: country.flag});
          }
        });
      });

      // TODO: Think about scalable solution for 'testing'; maybe moving it in environments as a whitelist?
      return tenants.filter(
        tenant => tenant.code === 'testing' || tenant.code.indexOf(app.toLowerCase()) !== -1
      ).sort((a, b) => a.name.localeCompare(b.name));
    }));
  }

  // Sanitize and handle null values
  private readTenantFromStorage(): string {
    let tenant = '';
    try {
      tenant = JSON.parse(localStorage.getItem(this.selectedTenantKey)) as string;
    }
    catch { }
    return tenant;
  }
}
