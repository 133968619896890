import {Component, ChangeDetectionStrategy, HostBinding, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'rea-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
/**
 * Projects the provided content inside of a material expansion panel.
 * Overrides the underlying panel's display from 'block' to 'content'
 * and decorates the host to act as the panel itself, allowing the client
 * code to manipulate the styling of the card (width, background-color, etc).
 */
export class ExpansionPanelComponent {
  /**
   * Decorates the host tag (rea-expansion-panel) with the 'mat-expansion-panel' class;
   */
  // @HostBinding('class') class = 'mat-expansion-panel';

  /**
   * Stores a reference to the underlying material expansion panel.
   */
  @ViewChild('panel') private panel: MatExpansionPanel;
  readonly expandedByDefault = true;

  @Input() panelOpenState?: boolean;

  /**
   * Opens the underlying material expansion panel.
   */
  open(): void {
    this.panel.open();
  }

  /**
   * Closes the underlying material expansion panel.
   */
  close(): void {
    this.panel.close();
  }
}
