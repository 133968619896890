import { Component, ChangeDetectionStrategy, Inject, Injector, InjectionToken } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Injection tokens used by Angular to pass values over to instanciated modal components.
 */
export const MODAL_REF = new InjectionToken<string>('modal-ref', { providedIn: 'root', factory: () => 'modal-ref' });
export const MODAL_DATA = new InjectionToken<string>('modal-data', { providedIn: 'root', factory: () => 'modal-data' });

@Component({
  selector: 'rea-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * A basic modal that will be opened by the Angular Material modal factory.
 * Any component type that is requested will get rendered inside the modal instance.
 */
export class ModalComponent implements ModalRef {
  /**
   * Injector instance used to pass data and ModalRef to rendered inner component.
   */
  componentInjector: Injector;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalConfiguration
  ) {
    /**
     * Maps the necessary values to the appropiate injection tokens,
     * so data can be passed to the component that get's rendered inside the modal
     */
    this.componentInjector =
      Injector.create({
        providers: [
          { provide: MODAL_DATA, useValue: data.modalData },
          { provide: MODAL_REF, useValue: this }
        ], parent: injector
      });
  }

  /**
   * Propagate return value through the wrapped services.
   */
  close(result?: any): void {
    this.dialogRef.close(result);
  }
}

export interface ModalConfiguration {
  component: any;
  modalData?: any;
}

export interface ModalRef {
  close(value?: any): void;
}
