<ng-container *ngIf="showBanner">
  <rea-banner [type]="'warning'">
    <div [innerHTML]="bannerText"></div>
  </rea-banner>
</ng-container>
<div class="dashboard">
  <div class="dashboard-wrapper">
    <div class="dashboard-content">
      <div class="logo">REA UI</div>
      <rea-dropdown class="application-selector" [(ngModel)]="app" (ngModelChange)="setNewApp($event)" [options]="apps"  [uppercase]="true">
      </rea-dropdown>
      <mat-nav-list class="menu-list">
        <ng-container *ngFor="let route of routes">
          <a class="menu-item" mat-list-item [routerLink]="route.url" routerLinkActive="active"
            *reaAuthorization="route.permissions; op route.permissionsOperation">
            <span>
              <mat-icon class="menu-icon material-icons-outlined">{{route.icon}}</mat-icon>
            </span>
            {{route.name}}</a>
        </ng-container>
      </mat-nav-list>
      <rea-button class="logout-btn" color="primary" (clickHandler)="logout()">Logout</rea-button>
    </div>
    <img src="assets/img/coffeebreak.gif" alt="Coffee Break">
  </div>
</div>
