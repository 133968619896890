import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalRef, MODAL_DATA, MODAL_REF } from '../modal.component';

@Component({
  templateUrl: './pdf-preview-modal.component.html',
  styleUrls: ['./pdf-preview-modal.component.scss']
})
export class PdfPreviewModalComponent implements OnInit {
  url: SafeUrl;

  constructor(
    @Inject(MODAL_REF) private modalRef: ModalRef,
    @Inject(MODAL_DATA) public data: PdfPreviewModalData,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.url = this.base64ToBlob(this.data.base64);
  }

  close(): void {
    this.modalRef.close();
  }

  private base64ToBlob(base64: string): SafeUrl {
    const byteArray = [];
    byteArray.push(base64);
    const blob = new Blob(byteArray, {
      type: 'application/pdf',
    });
    const url = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url || '');
  }
}

export interface PdfPreviewModalData {
  title: string;
  base64: string;
}
