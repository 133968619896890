import { Component, Input, OnDestroy, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TextareaComponent } from '../textarea/textarea.component';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'rea-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Basic input wrapping a form field from Angular Material that uses an input.
 * Extends ControlValueAccess so it can bind it's value to properties either with [(ngModel)] or reactive forms.
 * Behavior changing properties (like disabled) are inherited implicitly by the ControlValueAccessor.
 * Input type is passed through an @Input.
 * Inherits properties of Textarea (placeholder).
 */
export class InputComponent extends TextareaComponent implements OnInit, OnDestroy {
  /**
   * Type of the Html input element.
   * Possible values:
   * color, date, datetime-local, email, month, number,
   * password, search, tel, text, time, url, week
   */
  @Input() type = 'text';

  @Input() matAutocomplete: MatAutocomplete;

  @Input() readonly;

  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
