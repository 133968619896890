import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringLength'
})
export class StringLengthPipe implements PipeTransform {

  transform(value: string, size: number): unknown {
    if (!value || size <= 3 || size >= value.length) {
      return value;
    }

    return `${value.slice(0, size - 3)}...`;
  }

}
