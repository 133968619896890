import { Component, OnInit, OnDestroy, Self, Optional, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ControlValueAccessorBaseComponent } from '@utils/control-value-accessor-base.component';

@Component({
  selector: 'rea-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
/**
 * Basic checkbox wrapping the checkbox from Angular Material.
 * Extends ControlValueAccess so it can bind it's value to properties either with [(ngModel)] or reactive forms.
 * Behavior changing properties (like disabled) are inherited implicitly by the ControlValueAccessor.
 */
export class CheckboxComponent extends ControlValueAccessorBaseComponent<any> implements OnInit, OnDestroy {
  /**
   * Adjust color scheme to the material palette.
   * Possible values: 'primary', 'accent', 'warn' and undefined
   */
  @Input() color: ThemePalette = null;
  /**
   * Determines the position of the label.
   */
  @Input() labelPosition: 'before' | 'after' = 'after';

  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
