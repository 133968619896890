<h2>Emails</h2>

<div class="result-container" *ngIf="dataSource">
  <table class="search-table" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email</th>
      <td mat-cell data-column="Email" *matCellDef="let element">
        <div class="centered-information">
          <span *ngIf="!element.metadata.resendOf">
            <mat-icon class="material-icons-outlined">email</mat-icon>
          </span>
          <span *ngIf="element.metadata.resendOf">Resent:&nbsp;</span>
          <a (click)="previewEmail(element)">{{element.subject}}</a>

          <!--todo: Can possibly be enabled again in the feature-->
          <!-- Restricted preview code for MMNL-->

<!--        <ng-container *ngIf="tenant && tenant === 'sterling-mmnl'; else differentTenant" >-->
<!--          <span matTooltip="The preview functionality is restricted for the moment!"-->
<!--                matTooltipPosition="right">{{element.subject}}</span>-->
<!--        </ng-container>-->
<!--        <ng-template #differentTenant>-->
<!--          <a (click)="previewEmail(element)">{{element.subject}}</a>-->
<!--        </ng-template>-->
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status</th>
      <td mat-cell data-column="Status" *matCellDef="let element">
        <span class="status-label" [class]="{
        'label-success': [statusList.Sent, statusList.Delivered].indexOf(element.status) > -1,
        'label-resent': [statusList.Sent, statusList.Delivered].indexOf(element.status) > -1 && element.metadata.resendOf,
        'label-danger': [statusList.Undeliverable, statusList.Failed].indexOf(element.status) > -1,
        'label-info': [statusList.Generated, statusList.Delivering, statusList.Enqueued, statusList.Prepared, statusList.Preparing].indexOf(element.status) > -1
        }">
          <span *ngIf="element.metadata.resendOf && element.status == statusList.Sent">RE-</span>
          {{element.status}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef> Attachments</th>
      <td mat-cell data-column="Attachments" *matCellDef="let element">
        <div *ngIf="!element.attachments?.length">No attachments</div>
        <div *ngFor="let attachment of element.attachments" class="centered-information">
          <mat-icon class="material-icons-outlined">attachment</mat-icon>
          <a (click)="previewAttachment(element.id, attachment)">{{attachment.filename}}</a>

          <!--todo: Can possibly be enabled again in the feature-->
          <!-- Restricted preview code for MMNL-->

          <!-- <ng-container *ngIf="tenant && tenant === 'sterling-mmnl'; else differentTenant" >-->
<!--          <span matTooltip="The preview functionality is restricted for the moment!"-->
<!--                matTooltipPosition="right">{{attachment.filename}}</span>-->
<!--          </ng-container>-->
<!--          <ng-template #differentTenant>-->
<!--            <a (click)="previewAttachment(element.id, attachment)">{{attachment.filename}}</a>-->
<!--          </ng-template>-->
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef> Email Address</th>
      <td mat-cell data-column="Email Address" *matCellDef="let element; let index = index">
        <ng-container *reaAuthorization="['email_resend']">
          <rea-input *ngIf="!element.metadata.resendOf" [(ngModel)]="element.to" (focusout)="onFocusOut(element)"
                     #email="ngModel" type="email" [pattern]="pattern" required></rea-input>
          <div *ngIf="element.metadata.resendOf">
            {{element.to}}
          </div>
        </ng-container>
        <ng-container *reaAuthorization="['email_resend']; op 'NOT'">
          <div>{{element.to}}</div>
        </ng-container>
        <ng-container *ngIf="!currentPermissions">
          <rea-input *ngIf="!element.metadata.resendOf" [(ngModel)]="element.to" (focusout)="onFocusOut(element)"
                     #email="ngModel" type="email" [pattern]="pattern" required></rea-input>
          <div *ngIf="element.metadata.resendOf">
            {{element.to}}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>
        Timestamp
      </th>
      <td mat-cell data-column="Timestamp" *matCellDef="let element">
        <ng-container *ngIf="!element.timestamp;else showTime">-</ng-container>
        <ng-template #showTime>
          <ng-container>{{element.timestamp | date: 'medium'}} </ng-container>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell data-column="Actions" *matCellDef="let element; let i = index">
        <div class="actions" *ngIf="!element.metadata.resendOf">
          <div *reaAuthorization="['email_resend']">
            <div matTooltip="Invalid email address" [matTooltipDisabled]="validateEmail(element.to)">
              <rea-button color="primary"
                          [disabled]="!validateEmail(element.to)"
                          (clickHandler)="resend(element)">
                Resend
              </rea-button>
            </div>
          </div>
          <ng-container *ngIf="element.metadata.requestId">
            <ng-container *reaAuthorization="['dev_team']">
              <rea-button (clickHandler)="downloadRequest(element.id, element.metadata.requestId)">
                <mat-icon class="material-icons-outlined">download</mat-icon>
                Request
              </rea-button>
            </ng-container>
          </ng-container>
          <ng-container *reaAuthorization="['email_resend','dev_team']; op 'NOT'">
            -
          </ng-container>
          <div *ngIf="!currentPermissions">
            <div matTooltip="Invalid email address" [matTooltipDisabled]="validateEmail(element.to)">
              <rea-button color="primary"
                          [disabled]="!validateEmail(element.to)"
                          (clickHandler)="resend(element)">
                Resend
              </rea-button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        [class.resent-element]="element.metadata.resendOf"></tr>
  </table>

  <mat-paginator class="paginator" [pageSize]="8"></mat-paginator>
</div>
