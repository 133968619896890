import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'rea-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Projects the provided content inside of a material sidenav.
 * Client code may interact with the sidenav through the exposed methods.
 */
export class SidenavComponent {
  /**
   * Stores a reference to the underlying material sidenav.
   */
  @ViewChild('sidenav') private sidenav: MatSidenav;
  readonly openedByDefault = true;

  constructor(private cdr: ChangeDetectorRef) {}

  toggle(): void {
    this.sidenav.toggle();
    this.cdr.detectChanges();
  }
}
