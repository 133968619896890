import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  bannerText: string;
  showBanner = false;

  constructor() {
    this.showBanner = false;
    this.bannerText =
      `REA will move to a new location starting with 30.01.2023. Full rights will be granted on this date. Until then, check your access rights here:
      <a class="redirection-link" href="https://rea-ui-3174634258.feature.rea-cloud.d-p.io/">
        https://rea-ui-3174634258.feature.rea-cloud.d-p.io/
      </a>`;
  }
}
