import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private readonly onboardingOption = 'onboarding';

  guidance$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  guidanceOn: boolean;

  constructor() {
    const guidanceOn = this.getStateFromStorage();
    this.guidance$.next(Boolean(guidanceOn));
  }


  set(guidance: boolean): void {
    localStorage.setItem(this.onboardingOption, JSON.stringify(guidance));
    this.guidance$.next(guidance);
  }

  get(): Observable<boolean> {
    return this.guidance$.asObservable();
  }

  getStateFromStorage(): string {
    let onboarding = '';
    onboarding = JSON.parse(localStorage.getItem(this.onboardingOption)) as string;
    return onboarding;
  }
}
