<mat-form-field appearance="outline" class="facade" floatLabel="always">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>

  <mat-select [formControl]="control" [multiple]="multiple" disableOptionCentering="true">

<!--    allow adding a custom dropdown trigger for dropdowns-->
    <mat-select-trigger class="dropdown-icon" *ngIf="hasIcon">
      <ng-content select="[trigger]"></ng-content>
    </mat-select-trigger>

    <mat-option *ngFor="let option of options" [value]="key ? option[key] : option">
      <div class="tenants-dropdown">
        <ng-container *ngIf="option.flag">
          <img class="rounded-border-flag flag-spacing" src="{{option.flag}}">
        </ng-container>
        <ng-container *ngIf="!uppercase">{{label ? option[label] : option}}</ng-container>
        <ng-container *ngIf="uppercase">{{(label ? option[label] : option) | uppercase}}</ng-container>
      </div>
    </mat-option>
  </mat-select>

  <span matPrefix class="outline-fix">
        <ng-content select="[prefix]"></ng-content>
    </span>

  <span matSuffix class="outline-fix">
        <ng-content select="[suffix]"></ng-content>
    </span>

  <mat-hint>
    <ng-content select="[hint]"></ng-content>
  </mat-hint>

  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
</mat-form-field>
