import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad, Route, UrlSegment,
  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '@core/store/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.mayVisit(route.data?.rights);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.mayVisit(childRoute.data?.rights);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.mayVisit(route.data?.rights);
  }

  /**
   * Checks if the rights required to access the page are met
   * @returns True if user should be allowed on the route; False if route should not be navigated to
   */
  private mayVisit(rights: string[] = []): Observable<boolean> {
    return this.userService.get().pipe(
      take(1),
      map((user) => {
        let allow = false;
        if (user?.rights?.length) {
          allow = rights.every(r => user.rights.find(ur => r === ur) != null);
        }
        if (!allow) {
          this.router.navigate(['/unauthorized']);
        }
        return allow;
      })
    );
  }
}
