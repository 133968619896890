import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@core/store/user.service';
import { environment } from '@env';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReaUserModel, UserModel } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Redirects to Fifa login page
   */
  login(): void {
    window.open(`${environment.apiUrl}/auth/login`, '_self');
  }

  /**
   * Clear up user session and redirects to Fifa login page
   */
  logout(): void {
    // todo: needs to be removed
    this.clearCookie();

    // delete all local storage data except for ngStorage-url
    const searchUrl = JSON.parse(localStorage.getItem('ngStorage-url'));
    localStorage.clear();
    if (searchUrl){
      localStorage.setItem('ngStorage-url', JSON.stringify(searchUrl));
    }

    window.open(`${environment.apiUrl}/auth/logout`, '_self');
  }

  /**
   * Requests user information from backend
   * @returns User information
   */
  authenticate(): Observable<Readonly<UserModel>> {
    const url = `${environment.apiUrl}/auth/me`;

    return this.http.get<ReaUserModel>(url).pipe(
      map((reaUser) => {
        const user: UserModel = {
          username: reaUser.username,
          firstName: reaUser.first_name,
          lastName: reaUser.last_name,
          applications: reaUser.applications,
          tenants: reaUser.tenants,
          rights: reaUser.rights
        };
        return user;
      }),
      catchError(() => {
        return of(null as UserModel);
      })
    );
  }

  // todo: should be removed
  clearCookie(): void {
    document.cookie.split(';').forEach((segment) => {
      document.cookie = segment.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }

}
