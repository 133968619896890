<mat-card class="always-fit-content">

    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>

  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
</mat-card>
