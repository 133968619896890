import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading$ = new BehaviorSubject<number>(0);
  private reqCount = 0;

  constructor(
  ) {
    this.loading$.next(0);
  }

  /**
   * Retrieves loading call count
   * @returns loading count
   */
  get(): Observable<number> {
    return this.loading$.asObservable();
  }

  /**
   * Sets the loading call count
   * @param increment Call incrementation number
   */
  set(increment: number): void {
    this.reqCount += increment;
    if(this.reqCount < 0) this.reqCount = 0;
    this.loading$.next(this.reqCount);
  }

}
