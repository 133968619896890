import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizationDirective } from './authorization.directive';

@NgModule({
  declarations: [
    AuthorizationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AuthorizationDirective
  ]
})
export class AuthorizationModule { }
