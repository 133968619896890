import {Injectable} from '@angular/core';
import {Article, ARTICLES, InformationSectionType} from '@core/onboarding/models/resource-center';

@Injectable({
  providedIn: 'root'
})
export class ResourceCenterService {

  getArticles(): Article[] {
    return [
      {
        type: InformationSectionType.events,
        title: 'How to update XML/JSON file',
      }, {
        type: InformationSectionType.events,
        title: 'How is a variable extracted fom the XML',
        content: ARTICLES.variable_xml,
        lastModified: new Date( 1668423482000)
      }, {
        type: InformationSectionType.events,
        title: 'How to save and publish an event'
      }, {
        type: InformationSectionType.files,
        title: 'How to upload a new version of a static file'
      }, {
        type: InformationSectionType.emails,
        title: 'How to import a HTML email'
      }, {
        type: InformationSectionType.emails,
        title: 'Stages of a template. What is a published template? What should you know?'
      }, {
        type: InformationSectionType.events,
        title: 'How to preview and trigger an event'
      }, {
        type: InformationSectionType.emails,
        title: 'How to add/edit a variable in your templates'
      }, {
        type: InformationSectionType.documents,
        title: 'Types of documents and how they work'
      }];
  }

  getArticleContent(): string {

    return ;
  }
}

