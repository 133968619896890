import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MODAL_DATA, MODAL_REF, ModalRef} from '@shared/modal/modal.component';
import {ReaManagableTemplate} from '@core/templates/models/template';

@Component({
  selector: 'rea-resend-search-modal',
  templateUrl: './resend-search-modal.component.html',
  styleUrls: ['./resend-search-modal.component.scss']
})
export class ResendSearchModalComponent implements OnInit {

  constructor(
    @Inject(MODAL_REF) private modalRef: ModalRef,
  ) { }

  ngOnInit(): void {

  }

  confirm(): void {
    this.modalRef.close(true);
  }

  close(): void {
    this.modalRef.close();
  }
}
