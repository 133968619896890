import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private readonly selectedApplicationKey = 'rea-app';
  private application$ = new BehaviorSubject<string>(null);

  constructor(
    private userService: UserService
  ) {
    const app = this.readAppFromStorage();
    this.application$.next(app);
  }

  /**
   * Returns an observable that emits the latest selection of the desired application
   * @returns Stream of selected applications
   */
  get(): Observable<string> {
    return this.application$.asObservable();
  }

  /**
   * Saves the selected application in localstorage, and updates behaviorsubject
   * @param app Application to be used
   */
  set(app: string): void {
    localStorage.setItem(this.selectedApplicationKey, JSON.stringify(app));
    this.application$.next(app);
  }

  /**
   * Returns the list of available applications the user may choose from
   * @returns List of available applications
   */
  getApplicationList(): Observable<string[]> {
    return this.userService.get().pipe(take(1), map((user) => {
      if (!user?.applications?.length) { return []; }

      return user.applications;
    }));
  }

  // Sanitize and handle null values
  private readAppFromStorage(): string {
    let app = '';
    try {
      app = JSON.parse(localStorage.getItem(this.selectedApplicationKey)) as string;
    }
    catch { }
    return app;
  }
}
