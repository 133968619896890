export enum InformationSectionType {
  events = 'Events',
  emails = 'Emails',
  documents = 'Documents',
  orderSearch = 'Order Search',
  files = 'Files',
  monitoring = 'Miles',
  configuration = 'Configuration'
}

export interface Article {
  type: InformationSectionType;
  title: string;
  content?: string;
  lastModified?: Date;
}

export const ARTICLES = {
  variable_xml: `
 <div>
      <p>All the dynamic data that we render in the emails/documents is extracted from XML/JSON.
      </p>
      <br>
      <p>Below you can find a small section of an XML.
      </p>


      <pre class="code-example">
  &lt;payload&gt;
    &lt;Extn OutletID="1119"/&gt;
    &lt;OrderLines&gt;
      &lt;OrderLine LevelOfService="MSH_PICKUP"/&gt;
      &lt;OrderLine LevelOfService="MSH_HOME_DEL"/&gt;
    &lt;/OrderLines&gt;
  &lt;/payload&gt;</pre>
      <br>
      <p>The XML is formed of tags and attributes.</p>

      <p><b>XML tags:</b> payload, Extn, OrderLines, OrderLine</p>
      <p><b>XML attributes:</b> LevelOfService , OutletID</p>


      <p>Imagine that tags are the drawers that you can help you organize the content inside(the attributes and their
        values).
        The values will be used in the template as variables.
      </p><br>
      <h3>Mapping a variable</h3>
      <p>Event data section contains the list of variables and their mappings as below: <br><br>
        <img src="assets/img/articles/variable_xml_1.PNG" alt="Event definition for a variable">

      <p>It has 3 sections: <br>
        <i>Variable name:</i> The name that will be used across our app (in the template as well),</p> <br>

      <p><i>Path:</i> The place where the value of this variable is extracted from (an enumeration of the tags with the
        attribute in the end),</p> <br>

      <p><i>Type:</i> The type of the variable. In the case of <b>STRING_VALUE</b>, the information is quite
        straightforward; it is a text specified on an
        attribute corresponding to a tag.</p><br>
      <p>For example attribute <i>OutletID</i> is mapped as:</p>
      <pre class="code-example">payload/Extn/@OutletID</pre>
      <br>
      <p>It is of type text so it will be set of type <b>STRING_VALUE</b> in the event.</p><br>

      <p><b>STRING_LIST </b> implies, as the name says, a list with strings, so a list of texts. It might not be as
        intuitive as STRING_VALUE, since the attribute is also a text. <br>
        Nonetheless, in this case, the attribute is present on a tag that is part of
        an iterative structure.</p>
      <p> In our case we have <b> &lt;OrderLines&gt; </b>>tag, which is an enumeration(a list) of &lt;OrderLine&gt; tags.</p>

      <p>This makes attribute LevelOfService mapped with the following path:</p>
      <pre class="code-example">payload/OrderLines/OrderLine/@LevelOfService</pre>
      <br>
      In the above example with STRING_LIST as a type, the variable will contain both "MSH_PICKUP" and
      "MSH_HOME_DEL"

      <br><br>
      <p>** If LevelOfService is set as type STRING_VALUE will only take the first value of the list, so
        "MSH_PICKUP", which is not the desired behavior.</p>
      <br><br>
    </div>
`
};
