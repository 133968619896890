import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@shared/ui-components.module';
import { AuthorizationModule } from '@shared/authorization/authorization.module';

import {SearchEmailsComponent} from '@shared/search-common/search-emails/search-emails.component';
import {SearchDocumentsComponent} from '@shared/search-common/search-documents/search-documents.component';
import {SearchMigratedComponent} from '@shared/search-common/search-migrated/search-migrated.component';
import { ResendSearchModalComponent } from './resend-search-modal/resend-search-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSortModule} from '@angular/material/sort';


@NgModule({
  declarations: [
    SearchEmailsComponent,
    SearchDocumentsComponent,
    SearchMigratedComponent,
    ResendSearchModalComponent
  ],
    imports: [
        CommonModule,
        UiComponentsModule,
        AuthorizationModule,
        MatTooltipModule,
        MatSortModule
    ],
  exports: [
    SearchEmailsComponent,
    SearchDocumentsComponent,
    SearchMigratedComponent,
    MatSortModule
  ]
})
export class SearchCommonModule { }
