import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TextareaComponent } from './textarea/textarea.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CardComponent } from './card/card.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ModalComponent } from './modal/modal.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { MatTableModule } from '@angular/material/table';
import { PdfPreviewModalComponent } from './modal/pdf-preview-modal/pdf-preview-modal.component';
import { HtmlPreviewModalComponent } from './modal/html-preview-modal/html-preview-modal.component';
import { StringLengthPipe } from '@core/pipes/string-length.pipe';
import { ChipComponent } from './chip/chip.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BannerComponent } from './banner/banner.component';

const materialModules: any[] = [
  MatInputModule, MatFormFieldModule, MatButtonModule, MatCheckboxModule,
  MatSlideToggleModule, MatSelectModule, MatCardModule, MatExpansionModule,
  MatSidenavModule, MatDialogModule, MatSnackBarModule, MatAutocompleteModule
];

const exportedMaterialModules: any[] = [
  MatTableModule, MatPaginatorModule, LayoutModule, MatToolbarModule, MatIconModule,
  MatListModule, MatSidenavModule, MatButtonModule, MatMenuModule, MatProgressBarModule, MatProgressSpinnerModule,
  MatCheckboxModule, MatTabsModule, MatAutocompleteModule
];

const formsModules: any[] = [FormsModule, ReactiveFormsModule];

const internalUseComponents: any[] = [ModalComponent];

const uiComponents: any[] = [
  InputComponent, ButtonComponent, ChipComponent, CheckboxComponent, ToggleComponent,
  TextareaComponent, DropdownComponent, CardComponent, ExpansionPanelComponent,
  SidenavComponent, ConfirmationModalComponent, StringLengthPipe, BannerComponent
];

@NgModule({
  declarations: [
    ...internalUseComponents,
    ...uiComponents,
    PdfPreviewModalComponent,
    HtmlPreviewModalComponent,
    ChipComponent
  ],
  imports: [
    CommonModule,
    ...formsModules,
    ...materialModules,
    ...exportedMaterialModules
  ],
    exports: [
        ...formsModules,
        ...uiComponents,
        ...exportedMaterialModules
    ]
})
export class UiComponentsModule { }
