import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {ApplicationService} from '@core/store/application.service';
import {TenantService} from '@core/store/tenant.service';
import {BaseComponent} from '@utils/base.component';
import {combineLatest} from 'rxjs';
import {UserService} from '@core/store/user.service';
import {UserModel} from '@core/auth/models/user';
import {LanguageService} from '@core/store/language.service';
import {BannerService} from '@core/store/banner.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  app: string;
  private oldApp: string;
  apps: string[];
  user: UserModel;
  routes: ReaNavRoute[] = [];
  bannerText: string;
  showBanner = false;

  constructor(
    private authService: AuthService,
    private appStore: ApplicationService,
    private tenantStore: TenantService,
    private languageStore: LanguageService,
    private userStore: UserService,
    private bannerService: BannerService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.authenticate().subscribe();

    this.getApplications();
    this.getUser();
    this.generateRoutes();

    if (!this.user.applications?.length  || !this.user.tenants?.length || !this.user.rights?.length) {
      this.router.navigate(['/forbidden']);
    }

    this.showBanner = this.bannerService.showBanner;
    this.bannerText = this.bannerService.bannerText;
  }

  private generateRoutes(): void {
    this.routes = [
      {url: '/events', name: 'Events', icon: 'account_tree', permissions: ['event_read']},
      {url: '/emails', name: 'Emails', icon: 'mail_outline', permissions: ['template_read']},
      {url: '/documents', name: 'Documents', icon: 'picture_as_pdf_outline', permissions: ['template_read']},
      {url: '/files', name: 'Uploaded files', icon: 'upload_file', permissions: ['file_read']},
      {url: '/order-search', name: 'Search', icon: 'manage_search', permissions: ['email_agent']},
      {url: '/monitoring', name: 'Monitoring', icon: 'insights', permissions: ['dev_team']},
      {url: '/configuration', name: 'Configuration', icon: 'settings', permissions: ['dev_team']}
    ];
  }

  private getUser(): void {
    this.userStore.get()
      .pipe(this.unsubscribeOnDestroy)
      .subscribe((user) => {
        this.user = user;
      });
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setNewApp(app: string): void {
    if (this.oldApp !== app) {
      // Clear selected tenant, language when changing apps
      this.tenantStore.set(null);
      this.languageStore.set(null);
    }
    this.oldApp = app;
    this.appStore.set(app);
  }

  logout(): void {
    this.authService.logout();
  }

  private getApplications(): void {
    combineLatest([
      this.appStore.get(),
      this.appStore.getApplicationList()
    ])
      .pipe(
        this.unsubscribeOnDestroy
      )
      .subscribe(([currentApp, apps]) => {
        this.app = currentApp || apps[0];
        this.oldApp = this.oldApp || this.app;
        this.apps = apps;
      }, () => { // error occurred
      });
  }

}

interface ReaNavRoute {
  url: string;
  name: string;
  icon: string;
  permissions?: string[];
  permissionsOperation?: 'AND' | 'OR';
}
