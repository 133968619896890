import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '@env';
import {DashboardComponent} from '@main/dashboard/dashboard.component';
import {HomeComponent} from '@main/home/home.component';
import {ForbiddenComponent} from '@main/forbidden/forbidden.component';
import {UnauthorizedComponent} from '@main/unauthorized/unauthorized.component';
import {AuthGuard} from '@core/guards/auth.guard';
import {LoggedInGuard} from '@core/guards/logged-in.guard';
import {LoginComponent} from '@main/login/login.component';
import {ApplicationGuard} from '@core/guards/application.guard';
import {CallCenterSearchComponent} from './call-center/call-center-search/call-center-search.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'dashboard',
    canActivate: [LoggedInGuard],
    component: DashboardComponent,
  },
  {
    path: 'search',
    canActivate: [LoggedInGuard],
    component: CallCenterSearchComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoggedInGuard, ApplicationGuard],
    canActivateChild: [LoggedInGuard, ApplicationGuard],
    children: [
      {
        path: 'events',
        loadChildren: () => import('../app/events/events.module').then(m => m.EventsModule),
        canLoad: [AuthGuard],
        data: {rights: ['event_read']}
      },
      {
        path: 'emails',
        loadChildren: () => import('../app/emails/emails.module').then(m => m.EmailsModule),
        canLoad: [AuthGuard],
        data: {rights: ['template_read']}
      },
      {
        path: 'documents',
        loadChildren: () => import('../app/documents/documents.module').then(m => m.DocumentsModule),
        canLoad: [AuthGuard],
        data: {rights: ['template_read']}
      },
      {
        path: 'files',
        loadChildren: () => import('../app/files/files.module').then(m => m.FilesModule),
        canLoad: [AuthGuard],
        data: {rights: ['file_read']}
      },
      {
        path: 'order-search',
        loadChildren: () => import('../app/search/search.module').then(m => m.SearchModule),
        canLoad: [AuthGuard],
        data: {rights: ['email_agent']}
      },
      {
        path: 'monitoring',
        loadChildren: () => import('../app/monitoring/monitoring.module').then(m => m.MonitoringModule),
        canLoad: [AuthGuard],
        data: {rights: ['dev_team']}
      },
      {
        path: 'configuration',
        loadChildren: () => import('../app/configuration/configuration.module').then(m => m.ConfigurationModule),
        canLoad: [AuthGuard],
        data: {rights: ['dev_team']}
      }
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
];

const envRoutes: Routes = environment.envSpecificRoutes || [];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [...envRoutes, ...routes],
      {useHash: true}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
