import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'rea-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Projects the provided content inside of a material card.
 * Overrides the underlying card's display from 'block' to 'content'
 * and decorates the host to act as the card itself, allowing the client
 * code to manipulate the styling of the card (width, background-color, etc).
 */
export class CardComponent {
  /**
   * Decorates the host tag (rea-card) with the 'mat-card' class;
   */
  // @HostBinding('class') class = 'mat-card';
 }
