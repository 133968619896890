import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@core/store/user.service';

@Directive({
  selector: '[reaAuthorization]'
})
export class AuthorizationDirective implements OnInit {
  private permissions = [];
  private logicalOp: 'AND' | 'OR' | 'NOT';
  private isHidden = true;
  private currentPermissions: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.get().subscribe(user => {
      this.currentPermissions = this.sanitize(user?.rights);
      this.updateView();
    });
  }

  @Input()
  set reaAuthorization(val: string[]) {
    this.permissions = this.sanitize(val);
    this.updateView();
  }

  @Input()
  set reaAuthorizationOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    // If no permissions are required, it's fine ...
    if (!this.permissions?.length) { return true; }

    // Without permission, life would be hard
    if (!this.currentPermissions?.length) { return false; }

    switch (this.logicalOp) {
      case 'NOT':
        return this.permissions.every(p => this.currentPermissions.find(cp => p === cp) == null);
      case 'OR':
        return this.permissions.some(p => this.currentPermissions.find(cp => p === cp) != null);
      case 'AND':
      default:
        return this.permissions.every(p => this.currentPermissions.find(cp => p === cp) != null);
    }
  }

  private sanitize(arr: string[]): string[] {
    return (arr || []).map(e => e.toUpperCase());
  }
}
