import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallCenterSearchComponent } from './call-center-search/call-center-search.component';
import { SearchCommonModule } from '@shared/search-common/search-common.module';
import { UiComponentsModule } from '@shared/ui-components.module';



@NgModule({
  declarations: [CallCenterSearchComponent],
  imports: [
    CommonModule,
    SearchCommonModule,
    UiComponentsModule
  ]
})
export class CallCenterModule { }
