import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InformationSectionType} from '@core/onboarding/models/resource-center';
import {ResourceCenterService} from '@core/onboarding/resource-center.service';
import {snakeToCamel} from '@utils/general-helpers';
import {animate, query, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'rea-resource-center',
  templateUrl: './resource-center.component.html',
  styleUrls: ['./resource-center.component.scss'],
  animations: [
    trigger('openDrawer', [
      state('open', style({transform: 'translateX(0vw)'})),
      state('closed', style({transform: 'translateX(calc(26vw + 1.5em))'})),
      transition('open => closed', [
        animate('700ms ease-in')
      ]),
      transition('closed => open', [
        animate('700ms ease-in')
      ]),
    ])]
})
export class ResourceCenterComponent implements OnInit {

  infoSectionType: string;
  articles: any;
  filteredArticlesByType: any;
  filteredArticlesByValue: any;
  selectedArticle: any;
  infoPanelOverview = true;
  searchValue = '';
  showInfoPanel = false;


  constructor(
    private resourceCenterService: ResourceCenterService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.articles = this.resourceCenterService.getArticles();
    this.filteredArticlesByValue = this.articles;
    this.retrieveContentOnType();

    this.router.events.subscribe(() => {
      if (this.infoPanelOverview) {
        this.retrieveContentOnType();
      }
    });
  }

  retrieveContentOnType(): void {
    this.infoSectionType = InformationSectionType[snakeToCamel(this.activatedRoute.snapshot.firstChild.routeConfig.path.toString())];
    this.filteredArticlesByType = this.articles.filter(val => val.type === this.infoSectionType);
    this.selectedArticle = this.resourceCenterService.getArticleContent();
    this.cdr.detectChanges();
  }


  switchToOverview(val): void {
    this.infoPanelOverview = val;
    this.searchValue = '';
    this.retrieveContentOnType();
    this.cdr.detectChanges();
  }

  private filterArticles(): void {
    const filterValue = this.searchValue.toLowerCase();
    if (this.searchValue === '') {
      this.filteredArticlesByValue = this.articles;
    }
    this.filteredArticlesByValue = this.articles.filter(option => option.title && option.title.toLowerCase().includes(filterValue));
    this.cdr.detectChanges();
  }

  resourceCenterState(): void {
    this.showInfoPanel = !this.showInfoPanel;
  }
}
