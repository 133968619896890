<div class="modal-wrapper">
  <div class="preview-header">
    <button mat-icon-button (click)="close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div>
    <div [innerHTML]="content"></div>
  </div>
</div>
