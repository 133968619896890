import {Component, ChangeDetectionStrategy, Input, ViewEncapsulation, Output, EventEmitter, OnInit} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'rea-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
/**
 * Basic buttons wrapping the Raised button schema from Angular Material.
 * Passes through native DOM events click, mouseenter, etc.
 * Behavior changing properties (like disabled) need to passed as an @Input and set on the button explicitly.
 */
export class ButtonComponent {
  /**
   * Adjust color scheme to the material palette.
   * Possible values: 'primary', 'accent', 'warn' and undefined
   */
  @Input() color: ThemePalette = null;
  /**
   * Whether the user can interact with the button or not.
   */
  @Input() disabled: boolean = null;

  @Output('clickHandler') click = new EventEmitter();

  /**
   * Handler for the click event(handles click only from the inner component and does not get fired on the wrapper)
   * @param event
   */
  clickHandlerMethod(event: Event): void {
    this.click.emit(event);
  }

}
