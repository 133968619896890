import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Optional, Self, ViewEncapsulation} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ControlValueAccessorBaseComponent} from '@utils/control-value-accessor-base.component';

@Component({
  selector: 'rea-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/**
 * Basic textarea wrapping a form field from Angular Material that uses a textarea.
 * Extends ControlValueAccess so it can bind it's value to properties either with [(ngModel)] or reactive forms.
 * Behavior changing properties (like disabled) are inherited implicitly by the ControlValueAccessor.
 */
export class TextareaComponent extends ControlValueAccessorBaseComponent<any> implements OnInit, OnDestroy {
  /**
   * Placeholder text for when nothing is selected.
   */
  @Input() placeholder = '';
  @Input() rows: number;

  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
