/**
 * File containing multiple app wide helper functions
 */


/**
 * transforms any snake case string to be camel case
 */
export const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

