import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MigratedDocuments} from '@core/search/search-summary';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'rea-search-migrated',
  templateUrl: './search-migrated.component.html'
})
export class SearchMigratedComponent implements AfterViewInit, OnInit, OnChanges{

  @Input() data: Array<MigratedDocuments>;
  dataSource = new MatTableDataSource<MigratedDocuments>();

  @Output() resendEmitter = new EventEmitter();

  displayedColumns: string[] = ['document', 'name', 'emailAddress', 'sentTime', 'actions'];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly pattern = /.{1,}@[1-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/;

  constructor() { }

  ngOnInit(): void{
    this.dataSource.data = this.data || [];
  }

  ngOnChanges(changes): void{
    if (changes){
      this.dataSource.data = this.data || [];
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  resend(el): void {
    this.resendEmitter.emit({
      email: el.emailAddress,
      id: el.id,
      firstName: el.metadata.AROMA_FIRST_NAME,
      lastName: el.metadata.AROMA_LAST_NAME,
      type: 'migrated'
    });
  }

  validateEmail(str: string): boolean {
    return this.pattern.test(str);
  }

}
