<div class="centered-content-page unauthorized-wrapper">
  <div class="logo">REA UI</div>
  <img src="assets/img/unauthorized.png" alt="unauthorized">
  <h1>Your session might be expired. Please try to login again!</h1>
  <h3 class="secondary-text">
    If you can't authenticate, please contact REA UI application owner!
  </h3>
  <rea-button color="primary" (clickHandler)="logout()">Retry login</rea-button>
</div>

