<div class="article-wrapper">
  <div class="article-header" id="dynamic-header">
    <button type="button" mat-icon-button>
      <mat-icon (click)="backToOverview()">chevron_left</mat-icon>
    </button>
    <div class="article-title">{{article.title }}</div>
  </div>
  <div class="article-body" (scroll)="onScroll($event)">
    <div class="date-container">
    <span class="material-icons-outlined">
    edit_calendar
    </span>
      Published {{article.lastModified | date:'mediumDate'}}
    </div>
    <h2>Overview</h2>
    <div [innerHTML]="article.content"></div>
    <div class="footer">Was this useful?
      <a
        href="mailto:mms.product.customer.retention-customer.communication@mediamarktsaturn.com?subject=Feedback for REA Article Library">
        <span>Let us know</span>
      </a>
    </div>
  </div>
</div>
