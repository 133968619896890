import {Component, OnDestroy, OnInit, Optional, Self, ViewEncapsulation} from '@angular/core';
import {NgControl} from '@angular/forms';
import {CheckboxComponent} from '../checkbox/checkbox.component';

@Component({
  selector: 'rea-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
/**
 * Basic slider wrapping the slide toggle from Angular Material.
 * Extends ControlValueAccess so it can bind it's value to properties either with [(ngModel)] or reactive forms.
 * Behavior changing properties (like disabled) are inherited implicitly by the ControlValueAccessor.
 * Inherits properties of Checkbox.
 */
export class ToggleComponent extends CheckboxComponent implements OnInit, OnDestroy {
  constructor(@Self() @Optional() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
