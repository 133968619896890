<mat-form-field appearance="outline" class="facade" floatLabel="always">
    <mat-label>
        <ng-content></ng-content>
    </mat-label>

    <textarea matInput [rows]="rows" [placeholder]="placeholder" [formControl]="control"></textarea>

    <span matPrefix>
        <ng-content select="[prefix]"></ng-content>
    </span>

    <span matSuffix>
        <ng-content select="[suffix]"></ng-content>
    </span>

    <mat-hint>
        <ng-content select="[hint]"></ng-content>
    </mat-hint>

    <mat-error>
        <ng-content select="[error]"></ng-content>
    </mat-error>
</mat-form-field>
