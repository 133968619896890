<ng-container>
  <div class="home" [ngClass]="{'banner-pagination' : showBanner}">
    <ng-container>
      <rea-banner [type]="'warning'" *ngIf="showBanner">
        <div [innerHTML]="bannerText"></div>
      </rea-banner>
    </ng-container>
    <ng-container *ngTemplateOutlet="homePagination"></ng-container>
  </div>
</ng-container>

<ng-template #homePagination>
  <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
  <mat-toolbar color="primary" class="header">
    <div class="menu">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
              *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <a routerLink="/dashboard">
        <div class="logo">REA UI</div>
      </a>
    </div>

    <div class="context">
      <rea-dropdown class="context-selector tenant-dropdown"
                    *ngIf="(isHandset$ | async) === false"
                    [ngModel]="tenant"
                    (ngModelChange)="setNewTenant($event)"
                    [options]="tenants"
                    [hasIcon]="true"
                    [disabled]="tenantStore.isTenantDisabled || !tenants"
                    key="code" label="name"
                    matTooltip="Choose tenant"
                    matTooltipPosition="below">
        <ng-container *ngIf="selectedTenant" trigger>
          <mat-icon>
            <img class="rounded-border-flag flag-spacing" src="{{selectedTenant.flag}}"></mat-icon>
          <span>{{selectedTenant.name}}</span>
        </ng-container>
      </rea-dropdown>

      <!--Fullscreen language selector-->
      <rea-dropdown class="context-selector language-dropdown"
                    *ngIf="(isHandset$ | async) === false"
                    [ngModel]="selectedLanguage"
                    (ngModelChange)="setLanguage($event)"
                    [options]="languages"
                    [hasIcon]="true"
                    [disabled]=" languageStore.isLanguageDisabled || !languages"
                    key="isoCode" label="displayedName"
                    matTooltip="Choose language"
                    matTooltipPosition="below">
        <ng-container trigger>
          <mat-icon class="material-icons-outlined">translate</mat-icon>
          <span>{{this.selectedLanguageObj.displayedName}}</span>
        </ng-container>
      </rea-dropdown>

      <button mat-button *ngIf="isHandset$ | async" [matMenuTriggerFor]="profileMenu">
        <mat-icon>language</mat-icon>
      </button>

      <!-- User settings -->
      <mat-menu #profileMenu="matMenu">
        <!-- Handheld options -->
        <ng-container *ngIf="isHandset$ | async">
          <!-- Handheld tenant picker -->
          <span mat-menu-item disableRipple *ngIf="!tenant || tenants.length <= 1">{{tenant}}</span>

          <button mat-menu-item *ngIf="tenant && tenants.length > 1" [matMenuTriggerFor]="tenantPicker"
                  xPosition="before">
            {{selectedTenant.name}}
          </button>
          <hr/>

          <span mat-menu-item disableRipple *ngIf="!languages || languages.length <= 1">{{languageName}}</span>
          <button mat-menu-item *ngIf="languages && languages.length > 1" [matMenuTriggerFor]="languagePicker"
                  xPosition="before">
            {{languageName}}
          </button>
        </ng-container>
      </mat-menu>

      <!-- Tenant picker options -->

      <mat-menu #tenantPicker="matMenu">
        <button mat-menu-item *ngFor="let tenant of tenants" (click)="setNewTenant(tenant.code)">
          {{tenant.name}}
        </button>
      </mat-menu>

      <!--Language picker options -->
      <mat-menu #languagePicker="matMenu">
        <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language.isoCode)">
          {{language.name}}
        </button>
      </mat-menu>

    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-wrapper">

    <mat-sidenav #drawer class="sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
      <div class="sidenav-content">
        <div class="user">
          <mat-icon class="user-icon">account_circle</mat-icon>
          {{user?.username}}
        </div>
        <div class="application-label">
          <mat-icon class="material-icons-outlined app-icon">push_pin</mat-icon>
          <span color="primary">{{currentApp | uppercase}}</span>
        </div>
        <mat-nav-list class="menu-list">
          <p class="sidenav-subtitle">MENU</p>
          <ng-container *ngFor="let route of routes">
            <a class="menu-item" mat-list-item [routerLink]="route.url" routerLinkActive="active"
               *reaAuthorization="route.permissions; op route.permissionsOperation">
            <span>
              <mat-icon class="menu-icon material-icons-outlined">{{route.icon}}</mat-icon>
            </span>
              {{route.name}}</a>
          </ng-container>
        </mat-nav-list>
        <div class="guidance-settings">
<!--          commented this section until Guidance feature implemented-->

<!--          <p class="sidenav-subtitle" *reaAuthorization="['dev_team']">SETTINGS</p>-->
<!--          <div class="guidance-toggle" *reaAuthorization="['dev_team']">-->
<!--            <span>-->
<!--              Activate helpers-->
<!--            </span>-->
<!--            <span>-->
<!--              <rea-toggle [(ngModel)]="guidance"-->
<!--                          (ngModelChange)="activateGuidance()"-->
<!--                          matTooltip="Activate/deactivate tutorial"-->
<!--                          matTooltipPosition="right">-->
<!--            </rea-toggle>-->
<!--            </span>-->
<!--          </div>-->
        </div>
        <button class="logout-btn" mat-menu-item (click)="logout()">
          <mat-icon class="logout-icon">logout</mat-icon>
          Logout
        </button>
      </div>

    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <div class="page-content">
        <router-outlet></router-outlet>

        <ng-container *ngIf="router.url !== '/configuration'">
          <ng-container *reaAuthorization="['dev_team']">
            <rea-resource-center></rea-resource-center>
          </ng-container>
        </ng-container>

      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

