import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
/**
 * Hides the material snackbar factory implementation.
 */
export class AlertService {
  constructor(
    private snackBar: MatSnackBar
  ) { }

  /**
   * Opens a material snackbar with a given style and text
   * @param type `AlertType`
   * @param message Text to be displayed in alert
   */
  show(type: AlertType, message: string, duration: number = 3000): void {
    this.snackBar.open(message, null, {
      panelClass: [type, 'custom-snackbar'],
      verticalPosition: 'top',
      duration
    });
  }

  /**
   * Opens an alert with the given text as a successful alert
   */
  showSuccess(message: string, duration: number = 3000): void {
    this.show('success', message, duration);
  }

  /**
   * Opens an alert with the given text as a warning alert
   */
  showWarning(message: string, duration: number = 3000): void {
    this.show('warning', message, duration);
  }

  /**
   * Opens an alert with the given text as an error alert
   */
  showError(message: string, duration: number = 3000): void {
    this.show('error', message, duration);
  }
}

export type AlertType = 'success' | 'warning' | 'error';
