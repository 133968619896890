import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserService } from '@core/store/user.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.logIn();
  }

  private logIn(): void {
    this.authService.authenticate().subscribe((user) => {
      if (!user) {
        this.authService.login();
      }
      else {
        this.userService.set(user);

        const searchUrl = JSON.parse(localStorage.getItem('ngStorage-url'));
        if (searchUrl){
          this.router.navigate(['/search'], { queryParams: searchUrl.queryParams});
          localStorage.removeItem('ngStorage-url');
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }
}
