<h2>Archived documents</h2>
<div class="result-container" *ngIf="dataSource">
  <table class="search-table" mat-table [dataSource]="dataSource" matSort matSortActive="prepared_at" matSortDirection="desc">

    <ng-container matColumnDef="document">
      <th mat-header-cell *matHeaderCellDef> Document</th>
      <td mat-cell data-column="Document" *matCellDef="let element">
        <a (click)="previewDocument(element)">{{element.filename}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type</th>
      <td mat-cell data-column="Type" *matCellDef="let element">{{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="prepared_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
      <td mat-cell data-column="Timestamp" *matCellDef="let element">
        <ng-container *ngIf="element.prepared_at">{{element.prepared_at | date: 'medium'}}</ng-container>
        <ng-container *ngIf="!element.prepared_at">-</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell data-column="Actions" *matCellDef="let element">
        <ng-container *reaAuthorization="['dev_team']">
          <ng-container *ngIf="element.metadata.requestId">
            <rea-button (clickHandler)="downloadRequest(element.id, element.metadata.requestId)">
              <mat-icon class="material-icons-outlined">download</mat-icon>
              Request
            </rea-button>
          </ng-container>
          <ng-container *ngIf="!element.metadata.requestId">
            Request message not available
          </ng-container>
        </ng-container>
        <ng-container *reaAuthorization="['dev_team'] ; op 'NOT'">
          -
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="paginator" [pageSize]="5"></mat-paginator>
</div>
