
export interface EmailHistorySearch {
  attachments: FileName[];
  bcc: string[];
  body: EmailBody;
  cc?: string[];
  from: string;
  id: string;
  metadata: EmailMetadata;
  timestamp: number;
  status: Status;
  subject: string;
  to: string[];
}

export interface EmailMetadata {
  environment: string;
  event: string;
  locale: string;
  orderId: string;
  requestId: string;
  processType?: string;
  transactionId?: string;
  template: string;
  resendOf: string;
}

export interface EmailBody {
  content: string;
  type: string;
}


export interface FileName {
  filename: string;
}

export enum Status {
  Delivered = 'DELIVERED',
  Prepared = 'PREPARED',
  Enqueued = 'ENQUEUED',
  Sent = 'SENT',
  Undeliverable = 'UNDELIVERABLE',
  Generated = 'GENERATED',
  Failed = 'FAILED',
  Delivering = 'DELIVERING',
  Preparing = 'PREPARING'
}

export interface ArchivedDocument {
  filename: string;
  id: string;
  prepared_at: number;
  type: string;
  metadata: DocumentMetadata;
}

export interface DocumentMetadata {
  environment: string;
  isArchived: string;
  orderId: string;
  requestId: string;
  transactionId: string;
}


export interface MigratedDocuments {
  copyDocumentName: string;
  documentName: string;
  id: string;
  metadata: any;
  prepared_at: any;
}

export interface ExtendedMigratedDocument extends MigratedDocuments {
  name: string;
  emailAddress: string;
}
