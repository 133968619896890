<h2>Migrated documents</h2>
<div class="result-container" *ngIf="dataSource">
  <table class="search-table" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="document">
      <th mat-header-cell *matHeaderCellDef> Document </th>
      <td mat-cell data-column = "Document" *matCellDef="let element"> {{element.copyDocumentName}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell data-column = "Name" *matCellDef="let element">{{element.name | titlecase}}</td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef> Email Address </th>
      <td mat-cell data-column = "Email Address" *matCellDef="let element">
        <rea-input [(ngModel)]="element.emailAddress" type="email" [pattern]="pattern"></rea-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="sentTime">
      <th mat-header-cell *matHeaderCellDef> Timestamp </th>
      <td mat-cell data-column = "Timestamp" *matCellDef="let element">
        <ng-container *ngIf="element.prepared_at">{{element.prepared_at | date: 'medium'}}</ng-container>
        <ng-container *ngIf="!element.prepared_at">-</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *reaAuthorization="['email_resend']">
          Actions
        </ng-container>
      </th>
      <td mat-cell data-column="Actions" *matCellDef="let element">
        <div *reaAuthorization="['email_resend']">
          <div matTooltip="Invalid email address" [matTooltipDisabled]="validateEmail(element.emailAddress)">
            <rea-button color="primary"
                        [disabled]="!validateEmail(element.emailAddress)"
                        (clickHandler)="resend(element)">
              Resend
            </rea-button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="paginator" [pageSize]="5"></mat-paginator>
</div>
