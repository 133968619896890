import { Component, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel } from '@angular/forms';
import { BaseComponent } from './base.component';

@Component({
    template: ``
})
/**
 * Component base class that allows components derived from it to be considered as value accessors,
 * allowing client code to interect and manage an underlying value either with [(ngModel)] or
 * reactive form control.
 * Extends @BaseComponent.
 */
export class ControlValueAccessorBaseComponent<T> extends BaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * The underlying FormControl instance used by the value accessor.
     */
    public control: FormControl;
    /**
     * Stores the callback function provided by client code for when the control value changes.
     */
    private onChangeCallback: (_: T) => void = () => { };
    /**
     * Stores the callback function provided by client code for when the control is touched.
     */
    private onTouchedCallback: () => void = () => { };

    /**
     * Retrieves the inner value.
     */
    public get innerValue(): T {
        return this.control ? this.control.value : null;
    }

    /**
     * Sets the inner value. Emits the newly set value to change listeners through
     * (onModelChange) or FormControl.valueChanges.
     */
    public set innerValue(newValue: T) {
        if (this.control) {
            this.control.setValue(newValue);
            this.onChangeCallback(newValue);
        }
    }

    /**
     * Retrieves the value validity.
     */
    public get invalid(): boolean {
        return this.control ? this.control.invalid : false;
    }

    /**
     * Whether the control should show errors or not.
     * A control which has an invalid value may appear erroneous only
     * when it has been touched (onBlur) or modified by the user.
     */
    public get showError(): boolean {
        if (!this.control) {
            return false;
        }

        const { dirty, touched } = this.control;

        return this.invalid ? (dirty || touched) : false;
    }

    constructor(@Self() @Optional() private ngControl: NgControl) {
        super();
        if (this.ngControl != null) {
            /**
             * Setting the value accessor directly (instead of using the NG_VALUE_ACCESSOR provider)
             * to avoid running into a circular import.
             */
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        /**
         * Since the NgControl can be either an [(ngModel))], a [formControl] or a [formControlName]
         * the correct implementation needs to be identified in order to find it's underlying form control.
         */
        if (this.ngControl instanceof FormControlName) {
            // [formControlName]
            const formGroupDirective = this.ngControl.formDirective as FormGroupDirective;
            if (formGroupDirective) {
                this.control = formGroupDirective.form.controls[this.ngControl.name] as FormControl;
            }
        } else if (this.ngControl instanceof FormControlDirective) {
            // [formControl]
            this.control = this.ngControl.control;
        } else if (this.ngControl instanceof NgModel) {
            // [(ngModel)]
            this.control = this.ngControl.control;
            this.control.valueChanges.pipe(this.unsubscribeOnDestroy).subscribe(x => {
                this.ngControl.viewToModelUpdate(this.control.value);
            });
        } else if (this.ngControl && this.ngControl.control instanceof FormControl) {
            // This clause is used by unit tests.
            this.control = this.ngControl.control;
        } else if (!this.ngControl) {
            // Default to an empty form control.
            this.control = new FormControl();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * Mark control as touched after it was visited.
     */
    public onBlur(): void {
        this.onTouchedCallback();
    }

    /**
     * Sets the inner value of the value accessor.
     * @param value The new value.
     */
    public writeValue(value: T): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    /**
     * Sets the onChnageCallback to be whatever the cliend code provided.
     * @param callback Handler function provided by client code.
     */
    public registerOnChange(callback: (_: T) => void): void {
        this.onChangeCallback = callback;
    }

    /**
     * Sets the onTouchedCallback to be whatever the cliend code provided.
     * @param callback Handler function provided by client code.
     */
    public registerOnTouched(callback: () => void): void {
        this.onTouchedCallback = callback;
    }
}
