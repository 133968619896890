import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@Component({
  template: ''
})
/**
 * Component base class that allows components derived from it to attach @unsubscribeOnDestroy
 * when subscribing to observables, that will automatically unsubscribe when the component
 * is destroyed, preventing memory leaks.
 */
export class BaseComponent implements OnDestroy {
  /**
   * Subrect mirroring the components lifecycle state.
   */
  private isAlive$ = new Subject<void>();

  /**
   * Auto-unsubscribe all subscriptions when component is disposed.
   */
  public ngOnDestroy(): void {
    this.isAlive$.next();
    this.isAlive$.complete();
  }

  /**
   * When attached to an observabled, calls unsubscribe once the component is disposed of.
   * @param source Observable to which the client will subscribe.
   * @returns Source observable with attached operator.
   */
  protected unsubscribeOnDestroy = (source: Observable<any>): Observable<any> => {

    return source.pipe(
      takeUntil(this.isAlive$),
    );
  }
}
