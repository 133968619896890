import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '@core/store/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


  constructor(
    private router: Router,
    private loadingBar: LoadingService,
    private route: ActivatedRoute,
  ) {
  }

  // search URL is saved in local storage to be accessed after a successful login
  setSearch(): void{
    if (this.router.url.includes('/search?')) {
      const searchState = { url : '/search', queryParams: this.route.snapshot.queryParams};
      localStorage.setItem('ngStorage-url', JSON.stringify(searchState));
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.loadingBar.set(1);

    // todo: shall be removed since token in URL is no longer used for Call center search
    const tokenJson = localStorage.getItem('ngStorage-token');
    if (tokenJson) {
      const token = JSON.parse(tokenJson);
      request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
    });
    }

    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 403:
              this.setSearch();
              this.router.navigate(['forbidden']);
              break;
            case 401:
              this.setSearch();
              this.router.navigate(['unauthorized']);
              break;
            case 400:
              break;
            case 404:
              // alert
              break;
          }
        }
      }), finalize(() => {
      this.loadingBar.set(-1);
    }));
  }
}
